import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/singlettr/search', { params: request });
  },
  exportExcel: function (request) {
    return Utils.downloadFile('/api/singlettr/ExportExcel', 'singlettr.xlsx', request);
  },
  find: function (id) {
    return axios.get(`/api/singlettr/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/singlettr/${data.id}`, data);
    else return axios.post(`/api/singlettr`, data);
  },
  delete: function (id) {
    return axios.delete(`/api/singlettr/${id}`);
  },
  async uploadFile(file, isSecond) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('isSecond', isSecond);
    return axios.post(`api/singlettr/uploaddata`, formData);
  },
  async uploadSelectedData(items) {
    return axios.post(`api/singlettr/uploadselecteddata`, items);
  },
  changeStatusMulti: function (data) {
    return axios.patch(`/api/singlettr/changestatusmulti`, data);
  },
  async searchEventLog(request) {
    return await axios.get('/api/singlettr/searchEventLog', { params: request });
  },
  async getPermissions() {
    return await axios.get('/api/singlettr/eventLogPermissions');
  },
  getInitiators: function () {
    return axios.get('/api/singlettr/initiators');
  },
};
