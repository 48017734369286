<template>
  <div class="container-page">
    <h2 class="title title--h2 mb-0">Запись перечня односторонних мер таможенно-тарифного регулирования</h2>
    <div>
      <tabs>
        <tab name="Основная информация" :selected="true">
          <div class="form-group required">
            <ComponentInput
              label="Орган"
              :select2Settings="select2Settings"
              :select2Options="organs"
              v-model="card.organId"
              required
              type="select2"
            />
          </div>
          <div class="row">
            <div class="form-group required col-4">
              <div class="form-label">Дата решения</div>
              <DateComponent v-model="card.decisionDate" :clearable="false" required />
            </div>
            <div class="form-group required col-4">
              <ComponentInput
                v-model="card.decisionNumber"
                label="Номер решения"
                required
                max-length="50"
                placeholder="Введите номер решения"
              />
            </div>
            <div class="form-group col-4">
              <ComponentInput
                v-model="card.year"
                :disabled="true"
                type="number"
                label="Год"
                placeholder="Введите год"
              />
            </div>
          </div>
          <div class="form-group disabled-select required">
            <ComponentInput
              label="Товар"
              placeholder="Введите название товара"
              v-model="card.tnvedAlias"
              :maxLength="225"
              required
            />
          </div>
          <div class="form-group required">
            <ComponentInput
              label="Отрасль"
              :select2Settings="select2SettingsMulti"
              :select2Options="industries"
              v-model="card.singleTtrIndustryIds"
              type="select2"
              multiple
              required
            />
          </div>
          <div class="form-group">
            <ComponentInput
              label="Направление перемещения"
              :select2Settings="select2Settings"
              :select2Options="moveDirections"
              v-model="card.moveDirectionId"
              type="select2"
            />
          </div>
          <div class="form-group">
            <ComponentInput
              v-model="card.prolongation"
              label="Продление"
              max-length="255"
              placeholder="Укажите возможность продления"
            />
          </div>
          <div class="form-group">
            <ComponentInput v-model="card.comment" type="textarea" label="Комментарии" />
          </div>
          <div class="form-group">
            <div class="checkboxInput">
              <input type="checkbox" v-model="card.isActive" id="chActive" />
              <label for="chActive">Запись активна</label>
            </div>
          </div>
        </tab>
        <tab name="Ставки">
          <div class="mt-2">
            <ButtonStock title="Добавить запись" @click="addHistoryRecord"></ButtonStock>
            <div v-if="card.singleTtrHistory" class="mt-2">
              <div class="table-container mb-16 js-table-arrow">
                <table class="stockTable">
                  <thead>
                    <tr>
                      <th rowspan="2">Код ТН ВЭД ЕАЭС</th>
                      <!-- <th rowspan="2">Код ТН ВЭД ЕАЭС после</th> -->
                      <th rowspan="2">Ставка до</th>
                      <th rowspan="2">Ставка после</th>
                      <th colspan="2" class="text-center">Срок применения меры</th>
                      <th rowspan="2"></th>
                    </tr>
                    <tr>
                      <th>от</th>
                      <th>до</th>
                    </tr>
                  </thead>
                  <tbody v-if="card.singleTtrHistory.length">
                    <tr v-for="(item, index) in card.singleTtrHistory" :key="index">
                      <td class="col-tnved">
                        <div class="disabled-select">
                          <ComponentInput
                            v-model="item.tnvedNameBefore"
                            @update:modelValue="resetTnvedId(index, 'tnvedBeforeId')"
                            required
                            disabled
                          />
                          <i
                            class="fas fa-search catalog-icon catalog-icon-shifted"
                            title="Найти в каталоге"
                            @click="openTnvedCatalog(index, 'tnvedBeforeId', 'tnvedNameBefore')"
                          ></i>
                        </div>
                      </td>
                      <td class="col-rate">
                        <ComponentInput v-model="item.rateBefore" type="text" placeholder="Введите ставку до" />
                      </td>
                      <td class="col-rate">
                        <ComponentInput v-model="item.rateAfter" type="text" placeholder="Введите ставку после" />
                      </td>
                      <td>
                        <DateComponent v-model="item.applyDateFrom" :clearable="true" />
                      </td>
                      <td>
                        <DateComponent v-model="item.applyDateTo" :clearable="true" />
                      </td>
                      <td>
                        <IconComponent @click="deleteHistoryRecord(index)" name="delete" />
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="!card.singleTtrHistory.length">
                    <tr>
                      <td colspan="2">Нет данных.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </tab>
      </tabs>
    </div>
    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
  <section>
    <ModalComponent v-model="groupModal" title="Выбор группы товаров" @close="closeGroupCatalog">
      <CatalogSelector
        v-bind:selected-ids="productIds"
        route="nsitnved"
        :multiple="false"
        :select-groups-only="true"
        @select="updateGroup"
        @close="closeGroupCatalog"
      ></CatalogSelector>
    </ModalComponent>
    <ModalComponent v-model="tnvedModal" title="Выбор товара" @close="closeTnvedCatalog">
      <CatalogSelector
        v-bind:selected-ids="productIds"
        route="nsitnved"
        :multiple="false"
        :select-leafs-only="false"
        @select="updateTnved"
        @close="closeTnvedCatalog"
      ></CatalogSelector>
    </ModalComponent>
  </section>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';
  import Utils from '@/common/utils';

  import ComponentInput from '../../../common/components/ComponentInput';
  import DateComponent from '../../../common/components/dateComponent';
  import IconComponent from '../../../common/ui/IconComponent';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import CatalogApi from '../../nsi/api/catalog';
  import NsiApi from '../../nsi/api/nsi';
  import CatalogSelector from '../../nsi/components/CatalogSelector';
  import Api from '../api/singlettr';

  export default {
    name: 'SingleTtrOdnostoronCard',
    components: {
      IconComponent,
      DateComponent,
      ModalComponent,
      CatalogSelector,
      ButtonStock,
      ComponentInput,
      tabs,
      tab,
    },
    data() {
      return {
        id: this.$route.params.id,
        isSecond: true,
        card: {
          singleTtrHistory: [],
          singleTtrIndustryIds: [],
          tnvedId: null,
        },
        organs: [],
        groupModal: false,
        tnvedModal: false,
        productIds: [],
        currentTnvedIndex: -1,
        currentTnvedProp: '',
        currentTnvedPropText: '',
        industries: [],
        moveDirections: [],
        select2Settings: Constants.select2Settings,
        select2SettingsMulti: Constants.select2SettingsMultiple,
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          Api.find(this.id).then((response) => {
            this.card = response.data;
            this.card.isSecond = this.isSecond;
          });
        } else this.card.isSecond = this.isSecond;
      },
      loadDictionaries() {
        NsiApi.search('nsimeasureorganprotectedmarket', { pageNumber: 1, pageSize: 0, isActive: true })
          .then((response) => {
            this.organs = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error.response.data);
          });
        NsiApi.search('nsiindustry', { pageNumber: 1, pageSize: 0, isActive: true })
          .then((response) => {
            this.industries = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error.response.data);
          });
        Utils.loadSelectOptions('moveDirections', this.moveDirections, true);
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        let errors = [];
        if (this.card.singleTtrHistory?.length) {
          this.card.singleTtrHistory.forEach((item, index) => {
            if (item.applyDateTo && item.applyDateFrom > item.applyDateTo) {
              Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
              errors.push(
                `Некорректные данные в таблице ставок, запись ${index + 1}: дата "от" не может быть больше даты "до".`,
              );
            }
          });
        }
        if (errors.length) {
          Constants.alert.fire('Ошибка', errors.join('\n'), 'error');
          return;
        }

        Api.update(this.card)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (this.card.id) {
              this.$router.push({ name: 'SingleTtrOdnostoronCard', params: { id: this.card.id, action: 'edit' } });
            } else {
              this.$router.push({ name: 'SingleTtrRegistryOdnostoron' });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      updateGroup(selectedIds) {
        this.card.tnvedId = selectedIds[0];
        this.closeGroupCatalog();
      },
      closeGroupCatalog() {
        this.groupModal = false;
      },
      openTnvedCatalog(index, prop, propText) {
        this.currentTnvedIndex = index;
        this.currentTnvedProp = prop;
        this.currentTnvedPropText = propText;
        this.productIds = this.card.singleTtrHistory[index][prop] ? [this.card.singleTtrHistory[index][prop]] : [];
        this.tnvedModal = true;
      },
      updateTnved(selectedIds) {
        this.card.singleTtrHistory[this.currentTnvedIndex][this.currentTnvedProp] = selectedIds[0];
        if (selectedIds && selectedIds[0]) {
          CatalogApi.getListByIds('nsitnved', [selectedIds[0]]).then((response) => {
            console.log(response.data);
            this.card.singleTtrHistory[this.currentTnvedIndex][this.currentTnvedPropText] =
              response.data[0]?.code ?? '';
          });
        }
        this.closeTnvedCatalog();
      },
      closeTnvedCatalog() {
        this.tnvedModal = false;
      },
      addHistoryRecord() {
        this.card.singleTtrHistory.push({
          id: 0,
          singleTtrId: this.card.id,
          tnvedBeforeId: null,
          rateBefore: '0',
          tnvedAfterId: null,
          rateAfter: '0',
          applyDateFrom: null,
          applyDateTo: null,
        });
      },
      deleteHistoryRecord(index) {
        this.card.singleTtrHistory.splice(index, 1);
      },
      close() {
        this.$router.push({ name: 'SingleTtrRegistryOdnostoron' });
      },
      resetTnvedId(index, propName) {
        this.card.singleTtrHistory[index][propName] = null;
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        card: {},
      };
    },
    watch: {
      'card.decisionDate'(val) {
        if (val) {
          var currentYear = new Date(val).getFullYear();
          this.card.year = currentYear;
        } else {
          this.card.year = '';
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';
  .col-tnved {
    width: 25%;
  }
  .col-rate {
    width: 10%;
  }
  .stockTable {
    .catalog-icon-shifted {
      top: 18px;
      right: -15px;
    }
  }
</style>
